<template>
  <fw-layout wide>
    <template #debug>
      <fw-panel-info label="Justifications (raw)">
        <json-viewer :value="{ justifications }"></json-viewer>
      </fw-panel-info>
    </template>

    <fw-panel
      :title="$t('justifications')"
      featured
      :loading="creating"
      after-loading-checked
      :loading-error="creatingError"
    >
      <template #toolbar>
        <fw-button type="link" @click.native="createJustification">
          {{ $t('add') }}
        </fw-button>
      </template>
    </fw-panel>

    <fw-panel :title="$t('currentYear')" class="mt-5">
      <ContentLoader v-if="loading" />
      <fw-panel-info
        v-else-if="!currentYearJustifications || !currentYearJustifications.length"
        centered
        empty
        class="p-3"
      >
        {{ $t('noJustifications') }}
      </fw-panel-info>
      <div v-else>
        <RecordJustification
          v-for="item in currentYearJustifications"
          :key="item.key"
          :item="item.justification"
          :users="users"
        ></RecordJustification>
      </div>
    </fw-panel>
  </fw-layout>
</template>

<script>
import ContentLoader from '@/fw-modules/fw-core-vue/ui/components/animation/ContentLoader'
import RecordJustification from '@/components/records/RecordJustification'

import Dates from '@/fw-modules/fw-core-vue/utilities/dates'
import utils from '@/fw-modules/fw-core-vue/utilities/utils'

export default {
  components: {
    ContentLoader,
    RecordJustification
  },

  data() {
    return {
      loading: false,
      creating: false,
      creatingError: false,
      justifications: [],
      currentYearJustifications: [],
      users: {},
      year: Dates.now().year()
    }
  },

  computed: {
    api() {
      return this.$store.state.api.base
    },

    user() {
      return this.$store.getters.getUser
    }
  },

  mounted() {
    this.getJustifications()
  },

  methods: {
    async getJustifications() {
      this.loading = true

      await utils.tryAndCatch(this, async () => {
        const response = await this.api.getUserJustifications({ with_users: true })
        this.currentYearJustifications = response.data
        this.users = response.users
        console.log('getJustifications :>> ', response)
      })

      this.loading = false
    },

    async createJustification() {
      this.creating = true
      this.creatingError = false
      try {
        const response = await this.api.addUserJustification({
          absences: [],
          justification: {}
        })
        console.log('response :>> ', response)
        this.$router.push({
          name: 'absence-justification',
          params: { key: response.justification.key }
        })
      } catch (e) {
        console.error(e)
        this.creatingError = true
        this.$buefy.toast.open({
          message: this.$t('error'),
          type: 'is-danger',
          duration: 4000
        })
      }

      this.creating = false
    }
  }
}
</script>

<i18n>
{
  "pt": {
    "justifications": "Justificações",
    "currentYear": "Ano atual",
    "noJustifications": "Sem justificações",
    "add": "Adicionar justificação",
    "error": "Ocorreu um erro ao criar a justificação"
  },
  "en": {
    "justifications": "Justifications",
    "currentYear": "Current year",
    "noJustifications": "No justifications",
    "add": "Add justification",
    "error": "An error occurred when creating the justification"
  }
}
</i18n>
